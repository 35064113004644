// We have to import directly from lodash-es because of SSR only code in app router
import { matchesMultiple } from '@sortlist-frontend/utils';
import { flatten, uniq } from 'lodash-es';

import { COOKIE_EXPIRATION_FOUR_HOURS } from './constants';
import { Domain, Experiment, Market } from './types';

export const STAGING_GLOBAL_TLD: Domain = 'www.sortlist-test.com';
export const STAGING_ES_TLD: Domain = 'www-es.sortlist-test.com';
export const STAGING_NL_TLD: Domain = 'www-nl.sortlist-test.com';
export const STAGING_DE_TLD: Domain = 'www-de.sortlist-test.com';
export const STAGING_IT_TLD: Domain = 'www-it.sortlist-test.com';
export const STAGING_CH_TLD: Domain = 'www-ch.sortlist-test.com';
export const STAGING_FR_TLD: Domain = 'www-fr.sortlist-test.com';
export const STAGING_CA_TLD: Domain = 'www-ca.sortlist-test.com';
export const STAGING_US_TLD: Domain = 'www-us.sortlist-test.com';
export const STAGING_BE_TLD: Domain = 'www.sortlist-test.be';
export const STAGING_UK_TLD: Domain = 'www-uk.sortlist-test.com';

export const PROD_GLOBAL_TLD: Domain = 'www.sortlist.com';
export const PROD_FR_TLD: Domain = 'www.sortlist.fr';
export const PROD_ES_TLD: Domain = 'www.sortlist.es';
export const PROD_NL_TLD: Domain = 'www.sortlist.nl';
export const PROD_DE_TLD: Domain = 'www.sortlist.de';
export const PROD_IT_TLD: Domain = 'www.sortlist.it';
export const PROD_CH_TLD: Domain = 'www.sortlist.ch';
export const PROD_BE_TLD: Domain = 'www.sortlist.be';
export const PROD_CA_TLD: Domain = 'www.sortlist.ca';
export const PROD_US_TLD: Domain = 'www.sortlist.us';
export const PROD_UK_TLD: Domain = 'www.sortlist.co.uk';

// Pathnames that are not relevant for any experiment
export const nonRelevantPathnames = ['/robots.txt', '/sitemaps', '/_monitor', '/blog'].map(
  (pathname) => `${pathname}*/**`,
);

export function marketToDomains(market: Market): Domain[] {
  const domains: Domain[] = ['localhost'];
  switch (market) {
    case 'fr':
      domains.push(...[STAGING_FR_TLD, PROD_FR_TLD]);
      break;
    case 'es':
      domains.push(...[STAGING_ES_TLD, PROD_ES_TLD]);
      break;
    case 'nl':
      domains.push(...[STAGING_NL_TLD, PROD_NL_TLD]);
      break;
    case 'de':
      domains.push(...[STAGING_DE_TLD, PROD_DE_TLD]);
      break;
    case 'it':
      domains.push(...[STAGING_IT_TLD, PROD_IT_TLD]);
      break;
    case 'ch':
      domains.push(...[STAGING_CH_TLD, PROD_CH_TLD]);
      break;
    case 'ca':
      domains.push(...[STAGING_CA_TLD, PROD_CA_TLD]);
      break;
    case 'us':
      domains.push(...[STAGING_US_TLD, PROD_US_TLD]);
      break;
    case 'be':
      domains.push(...[STAGING_BE_TLD, PROD_BE_TLD]);
      break;
    default:
      domains.push(...[STAGING_GLOBAL_TLD, PROD_GLOBAL_TLD]);
  }
  return domains;
}

export const isPathnameActivatedForExperiment = (experiment: Experiment, requestUrl: string): boolean => {
  const url = new URL(requestUrl);
  const { pathname } = url;
  if (experiment.pathnames == null) return true;
  return matchesMultiple(experiment.pathnames, pathname);
};

export const isRouteActivatedForExperiment = (experiment: Experiment, requestUrl: string) => {
  const url = new URL(requestUrl);
  const { hostname, pathname } = url;

  const marketsForExperiment = experiment.markets;

  // No markets specified, just check for valid paths
  if (marketsForExperiment == null) {
    return !matchesMultiple(nonRelevantPathnames, pathname);
  }

  const domainsForExperiment = uniq(flatten(marketsForExperiment?.map(marketToDomains) ?? []));

  const domainMatchesExperimentConfig = domainsForExperiment.some((domain) => domain === hostname);

  if (!domainMatchesExperimentConfig) return false;

  // If we've gotten this far we just have to check that the matching pathname
  // does not fall in the banned ones
  return !matchesMultiple(nonRelevantPathnames, pathname);
};

export const fourHoursFromNow = () => {
  return new Date(Date.now() + COOKIE_EXPIRATION_FOUR_HOURS);
};
