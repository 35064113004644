import { Experiment, FeatureResult, GrowthBook, Result } from '@growthbook/growthbook';
import { uuidv4 } from '@sortlist-frontend/utils';
import { parse as parseCookie } from 'cookie';

import { config as defaultsConfig } from '../defaults';
import {
  AB_TESTS_COOKIE_ASSIGNATOR,
  AB_TESTS_COOKIE_NAME,
  AB_TESTS_COOKIE_SEPARATOR,
  CONTENT_REQUESTED_HEADER,
  GB_COOKIE_NAME,
} from './constants';
import {
  AvailableFeatureNames,
  FeatureFlagWithExperiment,
  isFeatureFlagWithExperiment,
  MergedFeatureToggles,
} from './types';
import { isRouteActivatedForExperiment } from './utils';

// This function is called both on the app server side and cloudflare worker
export async function getGrowthbookSSRData(request: Request, config: { gbApiHost: string; gbClientKey: string }) {
  const cookie = request.headers.get('Cookie') != null ? parseCookie(request.headers.get('Cookie')!) : undefined;
  const abTestCookieValue = cookie?.[AB_TESTS_COOKIE_NAME];
  let gbUuid = cookie?.[GB_COOKIE_NAME];

  const isDocumentRequest = request.headers.get(CONTENT_REQUESTED_HEADER) === 'document';

  /**
   * If the request comes from a google bot we want to completely bypass the growthbook calls and
   * simply consider no experiments are running. Also if the request is not a document request
   * we just return the default value
   */
  if (isGoogleBot(request) || !isDocumentRequest) {
    return { abTestCookieValue: '', gbUuid: '', shouldAddAbTestCookies: false };
  }

  // If both cookies are present, we return their value
  if (abTestCookieValue != null && gbUuid != null) {
    return { abTestCookieValue, gbUuid, shouldAddAbTestCookies: false };
  }

  const { configuredFeaturesWithExperiments, serverOnlyConfiguredExperiments } = getConfiguredFeaturesWithExperiments(
    request.url,
  );

  // If there are no configured "above the fold" experiments, then return
  // if there are non-"above the fold" experiments they'll run on the client side
  if (serverOnlyConfiguredExperiments.length === 0) {
    return { abTestCookieValue: '', gbUuid: '', shouldAddAbTestCookies: false };
  }

  if (gbUuid == null) {
    gbUuid = uuidv4();
  }

  const growthbookFeatures = await evaluateGrowthbookFeatures({
    gbUuid,
    config,
    configuredFeaturesWithExperiments,
    url: request.url,
  });

  const hasServerOnlyEvaluatedFeatures = Object.keys(growthbookFeatures).some((featureName) =>
    serverOnlyConfiguredExperiments.includes(featureName as AvailableFeatureNames),
  );

  // If there are some above the fold experiments but they all evaluated to false (e.g. the exp is not running)
  // then we don't need to set the cookie as they can all be evaluated client-side anyway
  if (!hasServerOnlyEvaluatedFeatures) {
    return { abTestCookieValue: '', gbUuid: '', shouldAddAbTestCookies: false };
  }

  return {
    abTestCookieValue: buildAbTestCookie(growthbookFeatures),
    gbUuid,
    shouldAddAbTestCookies: Object.keys(growthbookFeatures).length > 0,
    growthbookFeatures,
  };
}

export async function evaluateGrowthbookFeatures(
  props: {
    configuredFeaturesWithExperiments: AvailableFeatureNames[];
    url?: string;
  } & ({ gbUuid: string; config: { gbApiHost: string; gbClientKey: string } } | { gbInstance: GrowthBook }),
): Promise<Record<AvailableFeatureNames, FeatureResult<unknown>>> {
  const { configuredFeaturesWithExperiments } = props;

  let gb: GrowthBook;

  if ('gbInstance' in props) {
    gb = props.gbInstance;
  } else if ('gbUuid' in props) {
    gb = new GrowthBook<MergedFeatureToggles>({
      apiHost: props.config.gbApiHost,
      clientKey: props.config.gbClientKey,
      attributes: {
        id: props.gbUuid,
        url: props.url,
      },
    });
  } else {
    throw Error('Please provide at least a gbInstance or configuration to create one');
  }

  // TODO: check if timeout is too long
  await gb.init({ timeout: 1000 });

  const growthbookFeatures = {} as Record<AvailableFeatureNames, FeatureResult<unknown>>;
  for (const feature of configuredFeaturesWithExperiments) {
    const growthbookFeature = gb.evalFeature(feature);

    // Only include features that have an experiment running
    // or if the value from GB is "forced" via a temporary rollout
    if (growthbookFeature.experimentResult != null) {
      growthbookFeatures[feature] = growthbookFeature;
    } else if (growthbookFeature.source === 'force' && isFeatureFlagWithExperiment(defaultsConfig[feature])) {
      const experimentName = (defaultsConfig[feature] as FeatureFlagWithExperiment).experiment.key;

      // We only need `value` and `key` for the cookies
      const forcedExperimentResult: Partial<Result<unknown>> = {
        value: growthbookFeature.value,
        key: growthbookFeature.value,
        featureId: feature,
      };

      const forcedResult: FeatureResult<unknown> = {
        ...growthbookFeature,
        experiment: { key: experimentName } as Experiment<unknown>,
        experimentResult: forcedExperimentResult as Result<unknown>,
      };

      growthbookFeatures[feature] = forcedResult;
    }
  }

  return growthbookFeatures;
}

export const getConfiguredFeaturesWithExperiments = (requestUrl: string) => {
  const configuredFeaturesWithExperiments: AvailableFeatureNames[] = [];
  const serverOnlyConfiguredExperiments: AvailableFeatureNames[] = [];

  for (const [feature, config] of Object.entries(defaultsConfig)) {
    /**
     * We only get the experiments that:
     * - have an assigned feature flag
     * - are activated for this route (url)
     */
    if (isFeatureFlagWithExperiment(config) && isRouteActivatedForExperiment(config.experiment, requestUrl)) {
      // pass request url to see if exp active for this route
      configuredFeaturesWithExperiments.push(feature as AvailableFeatureNames);

      if (config.experiment.aboveTheFold) {
        serverOnlyConfiguredExperiments.push(feature as AvailableFeatureNames);
      }
    }
  }

  return { configuredFeaturesWithExperiments, serverOnlyConfiguredExperiments };
};

export function buildAbTestCookie(features: Record<AvailableFeatureNames, FeatureResult>): string {
  return Object.values(features)
    .map((feature) => `${feature.experiment!.key}${AB_TESTS_COOKIE_ASSIGNATOR}${feature.experimentResult!.key}`)
    .join(AB_TESTS_COOKIE_SEPARATOR);
}

const isGoogleBot = (request: Request): boolean => {
  const userAgent = request.headers.get('user-agent');
  return userAgent?.includes('google') ?? false;
};

export { GrowthBook } from '@growthbook/growthbook';
