import type { Location } from '@sortlist-frontend/shared-components';
import { isEmptyPlainObject, isNonEmptyString } from '@sortlist-frontend/utils/src/typeguards';

import { development } from '_public/static/domainInfo/development';
import { production } from '_public/static/domainInfo/production';
import { sandbox } from '_public/static/domainInfo/sandbox';
import { test } from '_public/static/domainInfo/test';
import { Domain, DomainConfig, DomainLocale, SupportedLangs } from '_public/static/domainInfo/types';
import { vercel } from '_public/static/domainInfo/vercel';

type Props = {
  origin: string;
};

export class DomainInfo {
  private currentDomain: Domain | null = null;
  private origin: string | null;
  private excludedIso31661: string[] = [];

  private constructor(private props: Props) {
    const { origin } = props;
    this.origin = origin;

    if (isNonEmptyString(origin)) {
      if (
        !origin.match(/^https?:\/\/([^\s\/?\.#]+\.?)+\/?(\/[a-z]{2}\/?)?$/) &&
        !origin.includes('localhost') &&
        !origin.includes('sandbox')
      ) {
        throw new Error('Origin must match https?://<domain>.<tld>(/<locale>/)?');
      }
      this.setDomainInfo(origin);
    }
  }

  private setDomainInfo(origin: string) {
    const domainInfoObject = DomainInfo.getDomainInfoObject(origin);
    try {
      if (origin.includes('localhost')) {
        this.currentDomain = domainInfoObject['http://www.sortlist.local'];
      } else if (origin.includes('vercel')) {
        this.currentDomain = vercel;
      } else if (origin.includes('sandbox')) {
        this.currentDomain = domainInfoObject[this.getDomainKeyForSandbox(origin)];
      } else {
        this.currentDomain = domainInfoObject[origin];
      }
      Object.keys(domainInfoObject).forEach((host: string) => {
        const { iso31661 } = domainInfoObject[host];
        if (iso31661 !== this.getIso31661() && iso31661) this.excludedIso31661.push(iso31661);
      });
    } catch (e) {
      throw new Error(`Domain info not present for origin: ${origin}`);
    }
  }

  private getDomainKeyForSandbox(origin: string) {
    const { hostname } = new URL(origin);

    const domainKey = hostname.split('-')[0];

    if (Object.keys(sandbox).includes(domainKey)) {
      return domainKey;
    }
    return 'pr';
  }

  getDomainInfoByIso31661(iso31661: string | null): { domain: Domain; url: string } | undefined {
    if (!this.origin) return;

    const domainInfoObject = DomainInfo.getDomainInfoObject(this.origin);

    const domainPair = Object.entries(domainInfoObject).find(([url, domain]) => {
      return domain.iso31661?.toUpperCase() === iso31661?.toUpperCase();
    });
    if (domainPair) {
      return {
        url: domainPair[0],
        domain: domainPair[1],
      };
    }
  }

  getOriginUrl(): string | null {
    return this.origin;
  }

  getIso31661(): string | null {
    if (!this.currentDomain) return null;

    return this.currentDomain.iso31661;
  }

  getCurrency(): string | null {
    if (!this.currentDomain) return null;

    return this.currentDomain.currency;
  }

  getLocation(locale: string): Location | null {
    if (!this.currentDomain) return null;

    const { locales = {}, placeId, main_locale: mainLocale, polygon, iso31661: isoCode } = this.currentDomain;
    if (!placeId || isEmptyPlainObject(locales)) return null;

    const iso31661 = isoCode || undefined;
    const terms: DomainLocale = locales[locale];
    if (terms) return { address: terms?.location, placeId, polygon, iso31661 };

    const defaultTerms: DomainLocale = locales[mainLocale as SupportedLangs];
    return defaultTerms ? { address: defaultTerms?.location, placeId, polygon, iso31661 } : null;
  }

  getMainLocale(): string | null {
    if (!this.currentDomain) return null;

    return this.currentDomain?.main_locale;
  }

  getLocales(): string[] | null {
    if (!this.currentDomain) return null;

    return Object.keys(this.currentDomain?.locales).map((locale) => locale);
  }

  getLanguages(): { language: string; locale: string }[] | null {
    if (!this.currentDomain) return null;

    return Object.entries(this.currentDomain?.locales).map(([locale, info]) => ({
      locale,
      language: info ? info.language : 'en',
    }));
  }

  hasBlog(): boolean {
    return !!this.currentDomain?.has_blog;
  }

  hasDataHub(): boolean {
    return !!this.currentDomain?.has_dataHub;
  }

  getExcludedIso31661(): string[] {
    return this.excludedIso31661;
  }

  getCountrySlug(locale: string): string | null {
    if (!this.currentDomain || this.getIso31661() === null) return null;
    const { locales } = this.currentDomain;
    if (!locales) return null;
    const key = locale as unknown as keyof typeof locales;
    if (!locales[key]) return null;
    const localeInfo = locales[key];
    if (localeInfo) {
      const { slug } = localeInfo;

      return slug ? slug : null;
    }
    return null;
  }

  /**
   * This function returns the current url (origin + locale).
   * If locale doesn't should not be present, return empty
   * @param locale current locale from context
   * @returns string url
   */
  getLocalizedUrl(locale: string | null): string {
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    return `${this.origin}${this.currentDomain?.main_locale === locale ? '' : `/${locale}`}`;
  }

  /**
   * This function returns the current locale path.
   * If locale doesn't should not be present, return empty
   * @param locale current locale from context
   * @returns string locale or empty
   */
  getLocalizedPath(locale: string | null) {
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    return `${this.currentDomain?.main_locale === locale ? '' : `/${locale}`}`;
  }

  /**
   * @throws Error if invalid syntax for origin
   */
  static getFromOrigin(origin: string | null): DomainInfo | null {
    if (!origin) return null;
    return new DomainInfo({ origin });
  }

  static getDomainInfoObject(origin: string): DomainConfig {
    if (origin.includes('.local')) return development;
    if (origin.includes('.sortlist-test.')) return test;
    if (origin.includes('.sandbox.')) return sandbox;
    if (origin.includes('.sortlist.')) return production;

    return development;
  }
}
