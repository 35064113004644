import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { isBrowser, isNonEmptyString, uniqBy } from '@sortlist-frontend/utils';
import { Kind } from 'graphql';

export const makeFragmentDeDupeLink = () => {
  return new ApolloLink((operation, forward) => {
    const definitions = uniqBy(operation.query.definitions, (def) => {
      if (def.kind === Kind.SCHEMA_DEFINITION) {
        return null;
      }
      if (def.kind === Kind.SCHEMA_EXTENSION) {
        return null;
      }
      return def.name?.value;
    });
    operation.query = {
      ...operation.query,
      // Remove duplicate fragment
      definitions,
    };
    return forward(operation).map((data) => {
      return data;
    });
  });
};

let token: string;

export const makeAuthMiddleware = (fetchAuthToken: () => Promise<string>) =>
  setContext(async () => {
    if (token == null) {
      token = await fetchAuthToken();
    }

    if (token != null && isNonEmptyString(token)) {
      return {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
    }
  });

export const coreApiUrl = isBrowser()
  ? process.env.NEXT_PUBLIC_CORE_API_URL_CLOUDFLARE
  : process.env.NEXT_PUBLIC_CORE_API_URL_INTERNAL;

export const publicApiUrl = isBrowser()
  ? process.env.NEXT_PUBLIC_PUBLIC_API_BASE_URL_CLOUDFLARE
  : process.env.NEXT_PUBLIC_PUBLIC_API_BASE_URL_INTERNAL;
