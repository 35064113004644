import '@sortlist-frontend/design-system/styles.css';
import '_assets/theme/style.scss';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { ApolloProvider, useApollo } from '@sortlist-frontend/data-fetching/public';
import {
  extractAbTestsFromCookies,
  FeatureFlagsProvider,
  useExtractFeatureFlagOverrides,
} from '@sortlist-frontend/feature-flags';
import { withSSRTranslations } from '@sortlist-frontend/translation/ssr';
import { GetServerSidePropsContext } from 'next';
import type { AppProps as NextAppProps } from 'next/app';
import { Inter, Playfair_Display } from 'next/font/google';
import { useRouter } from 'next/router';

import { extractOrigin } from '_config/domain.config';
import { createEmotionCache } from '_core/nextjs/create-emotion-cache';
import { translationsConfig } from '_core/utils/ssr';

/* istanbul ignore next */
const inter = Inter({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-sans-serif',
  display: 'swap',
});

/* istanbul ignore next */
const playfair = Playfair_Display({
  weight: ['400', '700'],
  subsets: ['latin'],
  variable: '--font-serif',
  display: 'swap',
});

// Workaround for https://github.com/zeit/next.js/issues/8592
export type AppProps = NextAppProps & {
  /** Will be defined only is there was an error */
  err?: Error;
  /** Explicit emotion cache required for mui v5 */
  emotionCache?: EmotionCache;
  /** Feature flag/Ab test config from the cookies */
  abTestCookie?: string;
  currentUrl?: string;
};

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp = (appProps: AppProps) => {
  const { Component, pageProps, emotionCache = clientSideEmotionCache, err, abTestCookie, currentUrl } = appProps;
  const router = useRouter();
  const { configOverride } = useExtractFeatureFlagOverrides(router.query, currentUrl, abTestCookie);
  const apolloClient = useApollo({ pageProps, schema: 'public' });

  return (
    <CacheProvider value={emotionCache}>
      <main id="__main" className={`${inter.className} ${playfair.variable}`}>
        <FeatureFlagsProvider enableExperiments configOverride={configOverride}>
          <ApolloProvider client={apolloClient}>
            <Component {...pageProps} err={err} />
          </ApolloProvider>
        </FeatureFlagsProvider>
      </main>
    </CacheProvider>
  );
};

MyApp.getInitialProps = async (context: { ctx: GetServerSidePropsContext }) => {
  const abTestCookie = extractAbTestsFromCookies(context.ctx.req);
  const origin = extractOrigin(context.ctx.req);

  return {
    abTestCookie,
    currentUrl: `${origin}${context.ctx.req.url}`,
  };
};

export default withSSRTranslations(MyApp, translationsConfig);
