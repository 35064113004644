/*eslint no-restricted-imports: ["error", { "patterns": ["*"] }]*/
/**
 * Why the rule above?
 * We need this file to stay as static as possible. If we start importing functions that generate booleans or values,
 * it will become difficult to keep track of the real values assigned to the configuration.
 * If you need dynamic behaviour, please do it wherever you're using the feature flag hooks.
 */

// eslint-disable-next-line no-restricted-imports
import { cardsExpertiseDescriptionPathnames, cardsTranslatedDescriptionPathnames } from './src/experiments/cards';
// eslint-disable-next-line no-restricted-imports
import { heroCTAPathnames } from './src/experiments/hero-cta';
// eslint-disable-next-line no-restricted-imports
import { FeatureToggleConfig } from './src/types';

export const config = {
  // Here as a default / example
  'my-feature-flag': {
    defaultValue: false,
    variants: ['control', 'variation-1', 'variation-2'],
    experiment: {
      key: 'test-experiment',
      markets: ['global'],
    },
  },
  'positive-review': {
    defaultValue: false,
    variants: ['control', 'variation-review'],
    experiment: {
      key: 'positive-review',
      markets: ['fr'],
    },
  },
  'experience-level': {
    defaultValue: false,
    variants: ['control', 'variation-donut', 'variation-bar'],
    experiment: {
      key: 'experience-level',
      markets: ['global'],
    },
  },
  'experience-level-donut': {
    defaultValue: false,
  },
  'reduce-display-information': {
    defaultValue: false,
    variants: ['control', 'variation-removal'],
    experiment: {
      key: 'reduce-display-information',
      markets: ['de'],
    },
  },
  'pause-campaign': {
    defaultValue: true,
    enabledForEnvironments: ['development'],
  },
  'ai-briefing': {
    defaultValue: false,
    variants: ['control', 'with-ai'],
    experiment: {
      key: 'ai-briefing',
    },
  },
  'cards-expertise-description': {
    defaultValue: false,
    variants: ['control', 'variation-cards-expertise-description'],
    experiment: {
      key: 'cards-expertise-description',
      aboveTheFold: true,
      pathnames: cardsExpertiseDescriptionPathnames,
    },
  },
  'cards-translated-description': {
    defaultValue: false,
    variants: ['control', 'variation-cards-translated-description'],
    experiment: {
      key: 'cards-translated-description',
      aboveTheFold: true,
      markets: ['global'],
      pathnames: cardsTranslatedDescriptionPathnames,
    },
  },
  'hero-cta': {
    defaultValue: false,
    variants: ['control', 'variation-continue'],
    experiment: {
      key: 'hero-cta',
      aboveTheFold: true,
      pathnames: heroCTAPathnames,
    },
  },
} as const satisfies Record<string, FeatureToggleConfig>;
