export const cardsExpertiseDescriptionPathnames = [
  '/a/creation-site-wordpress/**',
  '/a/design-landing-page/**',
  '/a/design-technique/**',
  '/a/developpement-front-end/**',
  '/a/optimisation-de-site-web/**',
  '/a/redaction-web/**',
  '/a/refonte-de-site-web/**',
  '/a/responsive-web-design/**',
  '/a/site-internet/**',
  '/a/wireframing/**',
] as const;

export const cardsTranslatedDescriptionPathnames = [
  '/a/app-store-optimisation/**',
  '/a/ecommerce-seo/**',
  '/a/keywords-management/**',
  '/a/link-building/**',
  '/a/local-seo/**',
  '/a/marketplace-seo/**',
  '/a/mobile-seo/**',
  '/a/multilingual-seo/**',
  '/a/off-page-seo/**',
  '/a/on-page-seo/**',
  '/a/seo/**',
  '/a/seo-audit/**',
  '/a/seo-consulting/**',
  '/a/seo-content-creation/**',
  '/a/seo-copywriting/**',
  '/a/seo-mobile-optimization/**',
  '/a/technical-seo/**',
] as const;
