export type ExperimentEventBody<ExperimentKey extends string = string, ExperimentVariant = string> = Record<
  ExperimentKey,
  ExperimentVariant
>;

/**
 * See https://github.com/sortlist/sortlist/blob/dev/src/rails/api-public/app/models/tracking_event.rb
 * for the backend allowed fields
 */
type EventType = 'experimentViewed' | 'experimentAssigned';

type TrackingDataBody = {
  attributes: {
    name: EventType;
    sent_at: string;
    ab_tests: {
      gbuuid: string;
      experiments: ExperimentEventBody;
      context: string;
    };
    url: string;
  };
};

export type TrackExperimentValues = {
  eventType: EventType;
  gbuuid: string;
  experiments: ExperimentEventBody;
  // Current url of the page the event is triggered on
  url: string;
  // The base url of where the tracking POST call should be made
  endpointBaseUrl: string;
  context: 'worker' | 'server' | 'client';
};

export const trackExperiment = (values: TrackExperimentValues, { onError }: { onError: (e: Error) => void }) => {
  const logEndpoint = `${values.endpointBaseUrl}/api/proxy/log`;

  return fetch(logEndpoint, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
    method: 'POST',
    body: JSON.stringify({
      data: {
        attributes: {
          name: values.eventType,
          sent_at: new Date().toISOString(),
          ab_tests: {
            gbuuid: values.gbuuid,
            context: values.context,
            experiments: values.experiments,
          },
          url: values.url,
        },
      } satisfies TrackingDataBody,
    }),
  }).catch(onError);
};
