import { DeepWriteable } from '@sortlist-frontend/utils';

import { config } from '../defaults';

export enum Locale {
  en = 'en',
  fr = 'fr',
  de = 'de',
  nl = 'nl',
  it = 'it',
  es = 'es',
}

export type Domain =
  | 'www.sortlist.com'
  | 'www.sortlist.fr'
  | 'www.sortlist.de'
  | 'www.sortlist.nl'
  | 'www.sortlist.it'
  | 'www.sortlist.es'
  | 'www.sortlist.co.uk'
  | 'www.sortlist.it'
  | 'www.sortlist.ca'
  | 'www.sortlist.us'
  | 'www.sortlist.ch'
  | 'www.sortlist.be'
  | 'www.sortlist-test.com'
  | 'www-fr.sortlist-test.com'
  | 'www-de.sortlist-test.com'
  | 'www-es.sortlist-test.com'
  | 'www-nl.sortlist-test.com'
  | 'www-it.sortlist-test.com'
  | 'www-ch.sortlist-test.com'
  | 'www-ca.sortlist-test.com'
  | 'www-us.sortlist-test.com'
  | 'www-uk.sortlist-test.com'
  | 'www.sortlist-test.be'
  | 'localhost';

// All markets where Sortlist is active (and for which there is a matching domain)
export type Market = 'global' | 'fr' | 'de' | 'nl' | 'de' | 'it' | 'es' | 'uk' | 'ca' | 'us' | 'ch' | 'be';

type RawAvailableFeatureToggles = DeepWriteable<typeof config>;
export type AvailableFeatureNames = keyof RawAvailableFeatureToggles;

export type AvailableFeatureToggles = {
  [Key in AvailableFeatureNames]: RawAvailableFeatureToggles[Key];
};

export type FlagValueString = `${string}-c` | `${string}-${string}`;

export type FeatureFlagValue = FlagValueString | ExperimentControlVariant;

export type ExperimentControlVariant = 'control';

export type Experiment = {
  key: string;
  // If true, it means the override from the cookies exists
  isRunning?: boolean;
  /**
   * If any market is given, the experiment will only be activated if the page is
   * loaded on a matching domain.
   * If not provided, the experiment will run on all markets/domains
   */
  markets?: readonly Market[];
  pathnames?: readonly string[];
  aboveTheFold?: boolean;
  // This is the gbuuid provided by Growthbook
  assignedUniqueId?: string;
};

export interface SimpleFeatureFlag {
  defaultValue: FeatureFlagValue | boolean;
}

export interface FeatureFlagWithVariants {
  defaultValue: FeatureFlagValue | boolean;
  variants: readonly FeatureFlagValue[];
}

export interface FeatureFlagWithExperiment extends FeatureFlagWithVariants {
  experiment: Experiment;
}

export type Environment = 'staging' | `sandbox-${number}` | 'sandbox' | 'development' | 'production';

export interface EnabledForConfig {
  /** If you need to enable the feature flag for production, just set the default value to true */
  enabledForEnvironments?: readonly Exclude<Environment, 'production'>[];
  enabledForMarkets?: readonly Market[];
}

// Only support simple feature flag for now
export type FeatureToggleConfig = (SimpleFeatureFlag | FeatureFlagWithVariants | FeatureFlagWithExperiment) &
  EnabledForConfig;

export type MergedFeatureToggles = Record<AvailableFeatureNames, FeatureToggleConfig>;

export interface FeatureFlagSetup {
  configOverride: Partial<MergedFeatureToggles>;
}

export function isFeatureFlagWithVariants(config: FeatureToggleConfig): config is FeatureFlagWithVariants {
  return 'variants' in config;
}

export function isFeatureFlagWithExperiment(config: FeatureToggleConfig): config is FeatureFlagWithExperiment {
  return 'experiment' in config;
}
