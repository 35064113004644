/* eslint-disable sonarjs/no-duplicate-string */
const pathnames = [
  '/analyse-des-medias-sociaux',
  '/consultoria-de-redes-sociales',
  '/crecimiento-en-redes-sociales',
  '/croissance-des-reseaux-sociaux',
  '/escucha-en-redes-sociales',
  '/estrategia-de-contenido-social',
  '/estrategia-en-linkedin',
  '/facebook-marketing',
  '/instagram-marketing',
  '/linkedin-marketing',
  '/linkedin-strategie',
  '/linkedin-strategy',
  '/marketing-de-reddit',
  '/marketing-des-reseaux-sociaux',
  '/marketing-en-linkedin',
  '/marketing-linkedin',
  '/marketing-pinterest',
  '/marketing-reddit',
  '/marketing-social',
  '/marketing-su-facebook',
  '/optimisation-des-reseaux-sociaux',
  '/optimizacion-de-redes-sociales',
  '/ottimizzazione-dei-mezzi-di-comunicazione-sociale',
  '/pinterest-advertising',
  '/pinterest-reclame',
  '/publicidad-en-pinterest',
  '/publicite-pinterest',
  '/publicite-snapchat',
  '/pubblicita-pinterest',
  '/pubblicita-snapchat',
  '/reddit-di-marketing',
  '/reddit-marketing',
  '/redes-sociales',
  '/sm-analytics',
  '/sm-analytik',
  '/snapchat-advertising',
  '/snapchat-reclame',
  '/social-content-strategy',
  '/social-listening',
  '/social-marketing',
  '/social-media',
  '/social-media-consultancy',
  '/social-media-consulting',
  '/social-media-copywriting',
  '/social-media-customization',
  '/social-media-groei',
  '/social-media-growth',
  '/social-media-listening',
  '/social-media-management',
  '/social-media-marketing',
  '/social-media-optimalisatie',
  '/social-media-optimization',
  '/social-selling',
  '/sociale-content-strategie',
  '/sociale-marketing',
  '/soziale-inhaltsstrategie',
  '/strategie-de-contenus-sociaux',
  '/twitter-strategie',
  '/twitter-strategy',
  '/venta-social',
] as const;

const createPathPatterns = (paths: readonly string[]) => {
  return paths.flatMap((path) => [`${path}/**`, `/s${path}/**`]);
};

export const heroCTAPathnames = createPathPatterns(pathnames);

export const heroCTAVariationName = 'variation-continue';
