import { Resolvers, TypePolicies } from '@apollo/client';

/**
 * You can add here any implementation of mock fields for existing entities
 * https://www.apollographql.com/docs/react/development-testing/client-schema-mocking
 */
export const customTypePolicies: TypePolicies = {
  Review: {
    fields: {
      isRecentlyRecommended: {
        read(_, { readField }) {
          const recommended = readField('recommended') as boolean;
          const createdAt = readField('createdAt') as Date;

          if (!recommended) return false;

          const cutOffDate = new Date().setMonth(new Date().getMonth() - 18);
          return new Date(createdAt) > new Date(cutOffDate);
        },
      },
    },
  },
  User: {
    fields: {
      fullName: {
        read(_, { readField }) {
          // If you need `name` for your computed property
          // you will need to request it on the query
          const firstName = readField('firstName') as string;
          const lastName = readField('lastName') as string;
          return `${firstName} ${lastName}`;
        },
      },
    },
  },
  MinimalUser: {
    fields: {
      fullName: {
        read(_, { readField }) {
          // If you need `name` for your computed property
          // you will need to request it on the query
          const firstName = readField('firstName') as string;
          const lastName = readField('lastName') as string;
          return `${firstName} ${lastName}`;
        },
      },
    },
  },
};

/**
 * You can add here any implementation of mock queries and mutations
 * Useful if the BE isn't ready yet
 * e.g. https://www.apollographql.com/docs/react/local-state/local-resolvers#async-local-resolvers
 */
export const customResolvers: Resolvers = {};
