import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject, ServerError } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { onError } from '@apollo/client/link/error';
import { Monitor } from '@sortlist-frontend/mlm';
import { isBrowser, isEqual, merge } from '@sortlist-frontend/utils';
import { print } from 'graphql';
import { useMemo } from 'react';

import coreApiPossibleTypes from '../core-api/possibleTypes.json';
import publicApiPossibleTypes from '../public-api/possibleTypes.json';
import { customResolvers, customTypePolicies } from './customProperties';
import { coreApiUrl, makeAuthMiddleware, makeFragmentDeDupeLink, publicApiUrl } from './utils';

if (process.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

type ApiSchema = 'core' | 'public';

const createHttpLink = (schema: ApiSchema) =>
  new HttpLink({
    uri: (schema === 'core' ? coreApiUrl : publicApiUrl) + '/graphql',
  });

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export const createApolloCache = (schema: ApiSchema) =>
  new InMemoryCache({
    possibleTypes: schema === 'core' ? coreApiPossibleTypes.possibleTypes : publicApiPossibleTypes.possibleTypes,
    typePolicies: {
      // Add custom, client-facing only fields here if needed
      // e.g. enhancing an existing types
      ...customTypePolicies,
    },
  });

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors != null) {
    for (const error of graphQLErrors) {
      const { message, path } = error;
      if (message.includes('error_in_previous_query')) {
        return;
      }
      const exception = `[GraphQL error]: Message: ${message} for ${operation.operationName}`;

      const data = {
        fullQuery: print(operation.query),
        variables: operation.variables,
        originalError: error,
        operation,
        path: path?.join(' > '),
      };
      console.error({ exception, data });
      Monitor.captureException(exception, { extra: data, tags: { graphqlOperation: operation.operationName } });
    }
  }
  if (networkError != null && (networkError as ServerError).statusCode !== 401) {
    const exception = `[Network error]: ${networkError} ${operation.operationName}`;

    const data = {
      fullQuery: print(operation.query),
      variables: operation.variables,
      originalError: networkError,
      operation,
    };
    console.error({ exception, data });
    Monitor.captureException(exception, { extra: data, tags: { graphqlOperation: operation.operationName } });
  }
});

function createApolloClient(fetchAuthToken: () => Promise<string>, schema: ApiSchema) {
  return new ApolloClient({
    connectToDevTools: true,
    ssrMode: !isBrowser(),
    link: from([makeFragmentDeDupeLink(), makeAuthMiddleware(fetchAuthToken), errorLink, createHttpLink(schema)]),
    cache: createApolloCache(schema),
    resolvers: {
      // Add custom resolvers if needed
      ...customResolvers,
    },
  });
}

type InitializeApolloBaseConfig = {
  initialState?: NormalizedCacheObject | null;
};

type InitializeApolloCoreConfig<T> = T & {
  schema: 'core';
  fetchAuthToken: () => Promise<string>;
};

type InitializeApolloPublicConfig<T> = T & {
  schema: 'public';
};

type InitializeApolloConfig =
  | InitializeApolloCoreConfig<InitializeApolloBaseConfig>
  | InitializeApolloPublicConfig<InitializeApolloBaseConfig>;

export function initializeApollo(config: InitializeApolloConfig) {
  const { schema, initialState } = config;

  const fetchAuthToken = isCoreSchemaConfig(config) ? config.fetchAuthToken : async () => '';

  const _apolloClient = apolloClient ?? createApolloClient(fetchAuthToken, schema);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState != null) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: unknown[], sourceArray: unknown[]) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (apolloClient == null) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props != null) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

type UseApolloBaseProps = {
  pageProps: any;
};

type UseApolloProps = InitializeApolloCoreConfig<UseApolloBaseProps> | InitializeApolloPublicConfig<UseApolloBaseProps>;

export function useApollo(props: UseApolloProps) {
  const { pageProps } = props;

  const state = pageProps?.[APOLLO_STATE_PROP_NAME];

  const fetchAuthToken = isCoreSchemaProps(props) ? props.fetchAuthToken : async () => '';

  return useMemo(
    () =>
      initializeApollo({
        initialState: state,
        schema: props.schema,
        fetchAuthToken,
      }),
    [state],
  );
}

function isCoreSchemaConfig(
  config: InitializeApolloConfig,
): config is InitializeApolloCoreConfig<InitializeApolloBaseConfig> {
  return config.schema === 'core';
}

function isCoreSchemaProps(props: UseApolloProps): props is InitializeApolloCoreConfig<UseApolloBaseProps> {
  return props.schema === 'core';
}
