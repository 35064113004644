import { ExperimentControlVariant } from './types';

export const FEATURE_FLAGS_OVERRIDE_PARAM = 'feature-flags';
export const FEATURE_FLAGS_OVERRIDE_ASSIGNATOR = ':';
export const FEATURE_FLAGS_OVERRIDE_SEPARATOR = ',';

export const AB_TESTS_COOKIE_NAME = 'ab-tests';
export const AB_TESTS_COOKIE_ASSIGNATOR = ':';
export const AB_TESTS_COOKIE_SEPARATOR = ',';
export const AB_TESTS_CONTROL: ExperimentControlVariant = 'control';

export const GB_COOKIE_NAME = 'gbuuid';

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-Fetch-Dest
export const CONTENT_REQUESTED_HEADER = 'sec-fetch-dest';

export const SET_COOKIE_HEADER = 'set-cookie';

// Expiration time for the ab test cookies
export const COOKIE_EXPIRATION_FOUR_HOURS = 4 * 60 * 60 * 1000;
