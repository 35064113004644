'use client';

import { GrowthBook } from '@growthbook/growthbook';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { captureException } from '@sortlist-frontend/mlm/standalone';
import { isEmptyPlainObject, merge, uuidv4 } from '@sortlist-frontend/utils';
import { getCookie, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';
import { useRouter as usePagesRouter } from 'next/compat/router';
import React, { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';

import { config } from '../defaults';
import { AB_TESTS_COOKIE_NAME, GB_COOKIE_NAME } from './constants';
import {
  buildAbTestCookie,
  evaluateGrowthbookFeatures,
  getConfiguredFeaturesWithExperiments,
} from './growthbook-server';
import { ExperimentEventBody, trackExperiment, TrackExperimentValues } from './tracking';
import { FeatureFlagSetup, MergedFeatureToggles } from './types';
import { useConfigOverrideFromCookies } from './useExtractFeatureFlagOverrides';
import { fourHoursFromNow } from './utils';

const FeatureFlagsContext = createContext<FeatureFlagSetup>({} as FeatureFlagSetup);

const useFeatureFlagContextValue = () => {
  const context = useContext(FeatureFlagsContext);
  if (isEmptyPlainObject(context)) {
    throw new Error(`useIsFeatureActive must be used within an FeatureFlagsProvider`);
  }
  return context as FeatureFlagSetup;
};

export const useMergedFeatureFlagConfig = () => {
  const configOverride = useFeatureFlagContextValue().configOverride;
  return merge({}, config, configOverride);
};

// Create a client-side GrowthBook instance
const gb = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  // Enable easier debugging of feature flags during development
  enableDevMode: process.env.NODE_ENV === 'development',
});

// Start downloading feature definitions
gb.init();

// Let the GrowthBook instance know when the URL changes so the active
// experiments can update accordingly. Only needed for non-SSR apps
function updateGrowthBookURL() {
  gb.setURL(window.location.href);
  gb.setAttributes({ url: window.location.href });
}

type FeatureFlagsProviderProps = FeatureFlagSetup & {
  enableExperiments?: boolean;
};

export const FeatureFlagsProvider = ({
  children,
  configOverride,
  enableExperiments = false,
}: PropsWithChildren<FeatureFlagsProviderProps>) => {
  const pagesRouter = usePagesRouter();
  const { cookie: gbUuidCookie, setCookie: setGbUuidCookie } = useCookie(GB_COOKIE_NAME);
  const { cookie: abTestCookie, setCookie: setAbTestCookie } = useCookie(AB_TESTS_COOKIE_NAME);
  const clientSideCookieOverridesRef = useRef<Partial<MergedFeatureToggles>>();

  useEffect(() => {
    if (pagesRouter != null) {
      pagesRouter.events.on('routeChangeComplete', updateGrowthBookURL);
      return () => pagesRouter.events.off('routeChangeComplete', updateGrowthBookURL);
    }
  }, []);

  useEffect(() => {
    const clientSideGrowthbookEval = async () => {
      const shouldSetCookie = gbUuidCookie == null || abTestCookie == null;

      if (shouldSetCookie) {
        /**
         * If we're on the client side and no cookie exits, evaluate running experiments
         * to set the cookies and get the true values for the client side.
         * Once the cookies have been set, they will be used in SSR as well
         */
        const gbUuid = gbUuidCookie ?? uuidv4();
        gb.setAttributes({ id: gbUuid });
        const { configuredFeaturesWithExperiments } = getConfiguredFeaturesWithExperiments(window.location.href);
        const evaluatedFeatures = await evaluateGrowthbookFeatures({
          gbInstance: gb,
          configuredFeaturesWithExperiments,
        });

        // Skip setting the ab test cookies if there are no evaluated features
        if (Object.keys(evaluatedFeatures).length === 0) return;

        const abTestCookie = buildAbTestCookie(evaluatedFeatures);

        // Set the new cookies from client side
        setAbTestCookie(abTestCookie, { expires: fourHoursFromNow(), path: '/' });
        setGbUuidCookie(gbUuid, { path: '/' });

        const clientSideCookieOverrides = useConfigOverrideFromCookies(window.location.href, {
          experimentsFromCookie: abTestCookie,
          gbUuid,
        });

        clientSideCookieOverridesRef.current = clientSideCookieOverrides;

        if (evaluatedFeatures != null) {
          const data: TrackExperimentValues = {
            eventType: 'experimentAssigned',
            url: window.location.href,
            gbuuid: gbUuid,
            experiments: Object.values(evaluatedFeatures).reduce((acc, feature) => {
              acc[feature.experiment!.key] = feature.experimentResult!.key;
              return acc;
            }, {} as ExperimentEventBody),
            endpointBaseUrl: window.location.origin,
            context: 'client',
          };
          trackExperiment(data, {
            onError: (error) => {
              captureException(`[Tracking error]: experimentAssigned failed in FeatureFlags provider`, {
                extra: { error, data },
              });
            },
          });
        }
      }
    };

    if (enableExperiments) {
      clientSideGrowthbookEval();
    }
  }, [gbUuidCookie]);

  return (
    <GrowthBookProvider growthbook={gb}>
      <FeatureFlagsContext.Provider
        value={{ configOverride: merge({}, configOverride, clientSideCookieOverridesRef.current) }}>
        {children}
      </FeatureFlagsContext.Provider>
    </GrowthBookProvider>
  );
};

function useCookie(key: string, defaultValue?: string) {
  const currentValue = getCookie(key)?.toString() ?? defaultValue;

  const [value, setValue] = useState(currentValue);

  const handleSetCookie = (value: string, options?: OptionsType) => {
    setValue(value);
    setCookie(key, value, options);
  };

  return {
    cookie: value,
    setCookie: handleSetCookie,
  };
}
