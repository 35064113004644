import {
  addBreadcrumb,
  captureEvent,
  captureException,
  captureMessage,
  ErrorBoundary,
  flush,
  setUser,
  withSentryConfig,
  wrapApiHandlerWithSentry,
} from '@sentry/nextjs';

export { browserTracingIntegration } from '@sentry/browser';

export { initClient } from './sentry.client';
export { initServer } from './sentry.server';
export { isIgnoredError } from './utils';

export { MonitoringProvider } from './MonitoringProvider';

// TODO: integrate functions within Logger as explained here: https://github.com/sortlist/discussions/issues/286
export const Monitor = {
  wrapApiHandlerWithSentry,
  captureException,
  captureEvent,
  captureMessage,
  ErrorBoundary,
  addBreadcrumb,
  flush,
  withSentryConfig,
  setUser,
};
